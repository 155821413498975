"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Storage = /** @class */ (function () {
    function Storage() {
        this.store = [];
        this.current = null;
    }
    Storage.prototype.stash = function (stats) {
        this.current = stats;
    };
    Storage.prototype.storeRawStats = function (stats) {
        this.store.push(stats);
    };
    Storage.prototype.flushRawStats = function (idToKeep) {
        if (!idToKeep) {
            this.store = [];
            return null;
        }
        var index = this.store.findIndex(function (elt) { return elt.getStatsId === idToKeep; });
        if (index > -1) {
            this.store.splice(0, index);
        }
        return this.store[0] || null;
    };
    Object.defineProperty(Storage.prototype, "referenceStats", {
        get: function () {
            return this.store[0] || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Storage.prototype, "stashedStats", {
        get: function () {
            return this.current;
        },
        enumerable: false,
        configurable: true
    });
    Storage.prototype.reset = function () {
        this.store = [];
    };
    return Storage;
}());
exports.default = Storage;
