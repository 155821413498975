"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Return the list of devices added or removed
var diff = function (update, current) {
    // Helper function to convert the list of devices into a map for fast lookup
    function toDeviceMap(list) {
        return list.reduce(function (map, device) {
            map[device.deviceId] = device;
            return map;
        }, {});
    }
    var oldDeviceMap = toDeviceMap(current);
    var newDeviceMap = toDeviceMap(update);
    var addedDevices = update.filter(function (device) { return !oldDeviceMap[device.deviceId]; });
    var removedDevices = current.filter(function (device) { return !newDeviceMap[device.deviceId]; });
    return {
        addedDevices: addedDevices,
        removedDevices: removedDevices
    };
};
var Devices = /** @class */ (function () {
    function Devices() {
        this.devices = [];
    }
    Devices.prototype.setDevices = function (listOfDevices) {
        if (this.devices.length === 0) {
            // Fill the initial list of devices
            this.devices = listOfDevices;
            return;
        }
        // compare with the existing list of devices and return the number of changes
        var diffDevices = diff(listOfDevices, this.devices);
        this.devices = listOfDevices;
        return diffDevices;
    };
    return Devices;
}());
exports.default = Devices;
