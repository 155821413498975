"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectAdvancedPlatform = void 0;
var removeExcessMozillaAndVersion = /^mozilla\/\d\.\d\W/;
var browserPattern = /(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g;
var engineAndVersionPattern = /^(cri|gec|edg)/;
var empty = "";
var isArray = Array.isArray;
var userAgentData = "userAgentData" in window.navigator ? window.navigator.userAgentData : null;
var userAgent = window.navigator.userAgent;
var mobiles = {
    iOS: /iphone/,
    iPadOS: /ipad|macintosh/,
    Android: /android/
};
var desktops = {
    Windows: /win/,
    macOS: /macintosh/,
    Linux: /linux/
};
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
var detectAdvancedPlatform = function (customUserAgent, customUserAgentData) { return __awaiter(void 0, void 0, void 0, function () {
    var uaFull, brandFull, brandName, brandVersion, osName, osVersion, architecture, majorPlatformVersion, ua_1, mobileOS, desktopOS, os, browserTest, versionRegex, safariVersion, saVersion, browserOffset, browserResult, browser, browserVersion, osVersion, ChromeOsVersionString, edgeMatch;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                // Use a provided UA string instead of the browser's UA
                userAgent = typeof customUserAgent === "string" ? customUserAgent : userAgent;
                // Use a provided UA data string instead of the browser's UA data
                userAgentData = typeof customUserAgentData === "string" ? customUserAgentData : userAgentData;
                if (!userAgentData) return [3 /*break*/, 2];
                return [4 /*yield*/, userAgentData.getHighEntropyValues(['architecture', 'bitness', 'brands', 'mobile', 'model', 'platform', 'platformVersion', 'uaFullVersion', 'fullVersionList', 'wow64'])];
            case 1:
                uaFull = _a.sent();
                brandFull = uaFull.fullVersionList.find(function (item) { var _a; return typeof item === "object" && item.brand && item.brand !== "Chromium" && !(((_a = item.brand) === null || _a === void 0 ? void 0 : _a.includes('Brand')) && false); });
                brandName = brandFull && brandFull.brand ? brandFull.brand.replace("Google ", "").replace("Microsoft ", "") : uaFull.fullVersionList && Array.isArray(uaFull.fullVersionList) && uaFull.fullVersionList.length > 0 ? uaFull.fullVersionList[0].brand || "unknown" : "unknown";
                brandVersion = uaFull.uaFullVersion;
                osName = uaFull.platform;
                osVersion = uaFull.platformVersion;
                architecture = uaFull.bitness;
                // specific case for Windows
                if (osName === "Windows") {
                    majorPlatformVersion = parseInt(osVersion.split('.')[0]);
                    if (majorPlatformVersion >= 13) {
                        osVersion = 11;
                    }
                    else if (majorPlatformVersion > 0) {
                        osVersion = 10;
                    }
                    else {
                        osVersion = 8;
                    }
                }
                if (uaFull.architecture === 'x86') {
                    if (uaFull.bitness === '64') {
                        architecture = "x86_64";
                    }
                    else if (uaFull.bitness === '32') {
                        architecture = "x86";
                    }
                }
                else if (uaFull.architecture === 'arm') {
                    if (uaFull.bitness === '64') {
                        architecture = "ARM64";
                    }
                    else if (uaFull.bitness === '32') {
                        architecture = "ARM32";
                    }
                }
                else {
                    architecture = uaFull.architecture + " " + uaFull.bitness;
                }
                return [2 /*return*/, {
                        name: brandName,
                        version: brandVersion,
                        majorVersion: brandVersion.split(".")[0],
                        nameMajorVersion: "".concat(brandName, "/").concat(brandVersion.split(".")[0]),
                        os: osName,
                        osVersion: osVersion,
                        nameVersion: "".concat(brandName, "/").concat(brandVersion),
                        nameOs: "".concat(brandName, "/").concat(osName),
                        nameVersionOs: "".concat(brandName, "/").concat(brandVersion, "/").concat(osName),
                        architecture: architecture,
                        mobile: uaFull.mobile,
                        model: uaFull.model,
                    }];
            case 2:
                if (userAgent) {
                    ua_1 = userAgent.toLowerCase().replace(removeExcessMozillaAndVersion, empty);
                    mobileOS = Object.keys(mobiles).find(function (os) { return mobiles[os].test(ua_1) && window.navigator.maxTouchPoints >= 1; });
                    desktopOS = Object.keys(desktops)
                        .find(function (os) { return desktops[os].test(ua_1); });
                    os = mobileOS || desktopOS;
                    browserTest = ua_1.match(browserPattern);
                    versionRegex = /version\/(\d+(\.\d+)*)/;
                    safariVersion = ua_1.match(versionRegex);
                    saVersion = isArray(safariVersion) ? safariVersion[1] : null;
                    browserOffset = browserTest && (browserTest.length > 2 && (engineAndVersionPattern.test(browserTest[1])) ? 1 : 0);
                    browserResult = browserTest && browserTest[browserTest.length - 1 - (browserOffset || 0)].split("/");
                    browser = browserResult && browserResult[0];
                    browserVersion = saVersion ? saVersion : browserResult && browserResult[1];
                    osVersion = browserVersion;
                    ChromeOsVersionString = ua_1.match(/os (\d+_\d+(_\d+)?)/);
                    if (browser === "crios") {
                        browser = "Chrome";
                        osVersion = ChromeOsVersionString ? ChromeOsVersionString[1].replace(/_/g, '.') : osVersion;
                    }
                    // Check specifically for Edge
                    if (ua_1.includes("edg/")) {
                        edgeMatch = ua_1.match(/edg\/(\d+\.\d+)/);
                        browser = "Edge";
                        browserVersion = edgeMatch && edgeMatch[1];
                    }
                    browser = capitalizeFirstLetter(browser || "");
                    return [2 /*return*/, {
                            name: browser,
                            version: browserVersion,
                            majorVersion: browserVersion === null || browserVersion === void 0 ? void 0 : browserVersion.split(".")[0],
                            nameMajorVersion: "".concat(browser, "/").concat(browserVersion === null || browserVersion === void 0 ? void 0 : browserVersion.split(".")[0]),
                            os: os,
                            osVersion: osVersion,
                            nameVersion: "".concat(browser, "/").concat(browserVersion),
                            nameOs: "".concat(browser, "/").concat(os),
                            nameVersionOs: "".concat(browser, "/").concat(browserVersion, "/").concat(os),
                        }];
                }
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
exports.detectAdvancedPlatform = detectAdvancedPlatform;
